.ac {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white;
}


.ac-content {
    display: flex;
    max-width: 1200px;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .ac-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ac-image {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .ac-text {
    flex: 1;
    margin-left: 20px;
  }

  .ac-text2{
    margin: 0px 68px 0px 68px;

  }

  .ac-text h1 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .ac-text p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .ac-text h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .ac-text ul {
    list-style-type: none;
    padding: 0;
  }
  
  .ac-text ul li {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .ac-text ul li span {
    color: #0f9d58;
    margin-right: 10px;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .ac-content {
      flex-direction: column;
    }
  
    .ac-images {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  
    .ac-image {
      width: 100%;
      margin-bottom: 0;
    }
    
    .ac-text {
      margin-left: 0;
    }

    .ac-text2{
      margin-left: 20px;
    }
  }
  