*{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.font-size-16 {
  font-size: 1.5rem;
}




