.content{
    width: 100%;
    padding: 0% 2% 0px 4%;
    .content{
    width: 100%;
    padding: 0% 2% 0px 4%;
}


.floating-whatsapp {
    position: fixed;
    background-color: silver;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    z-index: 1000;
    cursor: pointer;
}

.floating-whatsapp img {
    width: 30px;
    height: 30px;
}


.image{
    width: 100%;
    background-image: url(../img/imagereadmore.png);
    background-size:contain;
    background-repeat: no-repeat;
}

.subdiv_middle2{
    max-width:90% ;
    display: flex;
}

.home_middle2{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}

.content_subdiv{
    padding: 10px;
    width: 100%;
}

.content_subdiv p{
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.content_subdiv a{
    font-weight:400;
    background-color: #73737d5e;
    color: black;
    border-style: double ;
    border-radius: 10px;
    padding: 8px 15px 8px 15px;
}



.green_text{
    font-size: 1rem;
    font-style: italic;
    padding: 30px;
    background-color:#73737d31;
    margin-bottom: 50px;
}

.content_subdiv a:hover{
    background-color: #73737d31;
}

@media screen and (max-width: 768px) {
    .subdiv_middle2 {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }

    .image{
        width: 100%;
        background-size: cover;
        height: 500px;
        margin-bottom: 4%;
    }
    .image, .content {
        width: 100%; /* Ensure both image and content take full width */
        padding: 0; /* Remove any padding */
    }

    .content {
        padding: 0 4%; /* Adjust padding for smaller screens */
    }
}


}

.floating-whatsapp {
    position: fixed;
    background-color: silver;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    z-index: 1000;
    cursor: pointer;
}

.floating-whatsapp img {
    width: 30px;
    height: 30px;
}


.image{
    width: 100%;
    background-image: url(../img/imagereadmore.png);
    background-size:contain;
    background-repeat: no-repeat;
}

.subdiv_middle2{
    max-width:90% ;
    display: flex;
}

.home_middle2{
    margin-top: 5%;
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
}

.content_subdiv{
    padding: 10px;
    width: 100%;
}

.content_subdiv p{
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.content_subdiv a{
    font-weight:400;
    background-color: #73737d5e;
    color: black;
    border-style: double ;
    border-radius: 10px;
    padding: 8px 15px 8px 15px;
}



.green_text{
    font-size: 1rem;
    font-style: italic;
    padding: 30px;
    background-color:#73737d31;
    margin-bottom: 50px;
}

.content_subdiv a:hover{
    background-color: #73737d31;
}

@media screen and (max-width: 768px) {
    .subdiv_middle2 {
        flex-direction: column; /* Stack items vertically on smaller screens */
    }

    .image{
        width: 100%;
        background-size: cover;
        height: 500px;
        margin-bottom: 4%;
    }
    .image, .content {
        width: 100%; /* Ensure both image and content take full width */
        padding: 0; /* Remove any padding */
    }

    .content {
        padding: 0 4%; /* Adjust padding for smaller screens */
    }
}

