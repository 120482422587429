* {
    margin: 0px;
}

.logo {
    max-width: 70px;
    margin-left: 10px;
}

.bg-body-tertiary {
    background-color: #73737D !important;
}

.navbar-nav .nav-link {
    color: rgb(0, 0, 0) !important;
    font-size: 1.05rem;
    font-weight: 600;
}

.navbar-nav .nav-link:active {
    color: #9b9b9b !important;

}

span {
    margin-left: 7%;
}

.navbar-brand {
    color: #000000 !important;
}

.navbar-brand {
    display: flex;
    align-items: center;
}


a {
    color: rgb(0, 0, 0);
    text-decoration: none;
   
}

.header a {
    color: black;
    text-decoration: none;
    margin: 10px;
}

.header{
    background-color: #98938C;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

a:hover {
    color: #313131;
}

.navbar-brand:active {
    color: #9b9b9b !important;
}

.slogan {
    position: absolute;
    top: 55px;
    font-size: 10px;
    font-weight: bold;
    margin-left: 5px;
    white-space: nowrap;
}
.contact-icons{
    margin: 3px 0px 3px 0px;
}

.menu-icon{
    display: none;
}




@media screen and (max-width: 425px) {
    .head{
        background-color: #98938C;
    }
    .contact-icons { 
        margin: 0px;
        padding: 0px;
    }

    .social-icons{
        margin: 4px;
    }

    .slogan{
        top: 42px
    }
    .header{
        flex-direction: column;
        align-items: flex-start;
    }
    .icons a {
        margin: 0px;
        margin-left: 6px;
    }

    .navbar-brand {
        font-size: 1rem;
    }

    .logo {
        max-width: 50px;
    }
    .menu-icon {
        padding: 2px;
        margin-left: auto;
        display: flex;
        position: relative; /* Position relative to its original placement */
        z-index: 1100; /* Higher than the menu to ensure it remains on top */
        cursor: pointer; /* Indicate it's clickable */
        background-color: transparent; /* Optional: Remove button background */
        border: none; /* Optional: Remove button border */
    }
    
    /* Default hidden state */
    .menu-content {
        display: none; /* Hide by default */
    }
    
    /* Visible state when isMenuOpen is true */
    .menu-content.show {
        display: flex; /* Show menu */
    }
    .contact-icons{
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 320px) {
    .slogan{
        top: 35px
    }
    .nav-brand{
        margin: 0px !important;
    }

    a {
        margin: 0px;
    }

    .navbar-brand {
        font-size: 0.8rem;
    }

    .logo {
        margin-left: 6px;
        max-width: 35px;
    }


}