.footer_img{
    width: 100% !important;
    height: 400px;
}

@media (max-width: 768px){
    .footer_img{
        width: 100% !important;
        height: 200px;
    }
    .text-success {
        --bs-text-opacity: 1;
        margin-top: 30px;
        color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
    }
}