.footer {
    background-color: #383838 ;
    color: white;
    padding: 20px 50px 0px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.footer-section h1, .footer-section p, .footer-section a {
    font-size: 0.8rem;
}

.contact-icon{
    display: flex;
    flex-direction: column;
    color: #ffffff;
}


.footer-section {
    flex: 1;
    margin: 10px;
    min-width: 200px;
}

.footer-section h1 {
    font-size: 18px;
    border-bottom: 2px solid #ffffff;
    padding-bottom: 10px;
}


.footer-section ul {
    list-style-type: none;
    padding: 0;
}

.footer-section ul li {
    margin: 8px 0;
}

.footer-section ul li a {
    color: white;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.gallery-images {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 images per row on larger screens */
    gap: 10px; /* Space between images */
}

.gallery-images img {
    width: 100%; /* Ensure images fill the grid cell */
    height: auto; /* Maintain aspect ratio */
}
.footer-copyright {
    text-align: center;
    border-top: 1px solid #ffffff;
    width: 100%;
  }

/* Media Queries for responsiveness */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 20px;
    }

    .footer-section {
        margin: 20px 0;
        min-width: 100%;
    }

    .gallery-images {
        grid-template-columns: repeat(3, 1fr); /* 3 images per row even on small screens */
        gap: 5px; /* Smaller gap on mobile */
    }

    .gallery-images img {
        width: 100%; /* Adjust to fill the grid cell */
    }

}

@media (max-width: 480px) {
    .gallery-images {
        grid-template-columns: repeat(2, 1fr); /* 2 images per row on very small screens */
    }

    .gallery-images img {
        width: 100%; /* Ensure images fill the grid cell */
    }
}
