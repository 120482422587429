.parent_row {
    margin-top: 5%;
    display: flex;
    justify-content: center; /* Center the content */
}

.sub1_row {
    width: 90%;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    gap: 20px; /* Adds more space between items */
    justify-content: center; /* Ensures content is centered */
}

.sub_row {
    border: 2px solid #73737d6b;
    width: calc(25% - 20px); /* 4 columns with gap adjustment */
    padding: 20px; /* Adds more padding for better spacing */
    box-sizing: border-box; /* Includes padding in the width calculation */
    background-color: #fff; /* Optional: Adds a background color for better visibility */
    flex: 1 1 200px; /* Flexible width with a minimum width */
    text-align: center; /* Center text for consistency */
}

.title {
    margin: 20px 0;
    font-size: 1.2em; /* Slightly larger font size for better readability */
}

.para {
    font-size: 1em;
    line-height: 1.5; /* Improved line height for better readability */
    margin: 10px 0; /* Adds margin for spacing between paragraphs */
}

/* Media Query for Tablets */
@media screen and (max-width: 768px) {
    .sub_row {
        width: calc(50% - 20px); /* 2 columns with gap adjustment */
        padding: 15px; /* Adjust padding for smaller screens */
    }
}

/* Media Query for Mobile Phones */
@media screen and (max-width: 480px) {
    .sub_row {
        width: calc(100% - 20px); /* 1 column on smaller screens */
        padding: 15px; /* Reduce padding for mobile screens */
    }
}
