.about-us {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: white;
  }

.aboutus{
  height: 300px;
  width: 100%;
  background-image: url('../img//aboutusback.jpeg');
  background-size:cover;
  background-repeat: no-repeat;
}

.aboutus h1{
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.733);
}
  
  .about-us-content {
    display: flex;
    max-width: 1200px;
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  
  .about-us-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .about-us-image {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .about-us-text {
    flex: 1;
    margin-left: 20px;
  }
  
  .about-us-text h1 {
    font-size: 32px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-us-text p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .about-us-text h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .about-us-text ul {
    list-style-type: none;
    padding: 0;
  }
  
  .about-us-text ul li {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
    margin-bottom: 10px;
  }
  
  .about-us-text ul li span {
    color: #383838;
    margin-right: 10px;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 768px) {
    .about-us-content {
      flex-direction: column;
    }
  
    .about-us-images {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  
    .about-us-image {
      width: 100%;
      margin-bottom: 0;
    }
  
    .about-us-text {
      margin-left: 0;
    }
  }
  