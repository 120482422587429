.carousel-caption.custom-caption {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: rgba(0, 0, 0, 0.158);
    backdrop-filter: blur(5px);
    color: #fff;
    padding: 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  


  .carousel-caption.custom-caption h1 {
    color: black;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .carousel-caption.custom-caption h2 {
    color: black;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .carousel-caption.custom-caption p {
    color: black;
    font-size: 1.2rem;
    margin: 1rem 0;
  }
  .carousel-caption.custom-caption .btn {
    margin: 0.5rem 0;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
  }
  


  @media (max-width: 768px) {
    .carousel-caption.custom-caption {
      
      width: 60%;
      padding: 1rem;
    }
  
    .carousel-caption.custom-caption h1 {
        margin: 0px;
        padding:0px;
      font-size: 1.1rem;
    }
  
    .carousel-caption.custom-caption h2 {
      
        margin: 0px;
      font-size: 1rem;
    }
  
    .carousel-caption.custom-caption p {
        margin: 5px 0px 0px 0px;
      font-size: 1rem;
    }

    .carousel-caption.custom-caption .btn {
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
      }
      .slide{
        height: 300px;
      }
  }

  @media screen and (min-width: 769px) and (max-width:1258px) {
    .slide{
      height: 500px;
    }
  }

  @media screen and (max-width: 320px){
    .slide{
      height: 350px;
    }
  }
  