/* HomeServices.css */
.custom-card {
    width: 100%; /* Default width for all screens */
    max-width: 23rem; /* Max width for larger screens */
}


@media (max-width: 576px) {
    .custom-card {
        width: 90%; /* 90% width for mobile devices */
    }
}
